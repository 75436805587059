export const TIER_NAME: string[] = ["VICTORIA CLUB", "MONTE CARLO", "SAHARA CASINO", "GRAND HOLLYWOOD", "ACES POKER", "LOUNGE BAR", "CAESARS PALACE", "LAS VEGAS CASINO", "POKER CLUB", "DUBAI PALACE"]

export const BUYIN_OPTIONS: any[] = [
    { label:'1,000', value: 1000}, 
    { label:'5,500', value: 5500}, 
    { label:'10,000', value: 10000}, 
    { label:'20,000', value: 20000}, 
    { label:'55,000', value: 55000}, 
    { label:'220,000', value: 220000},
    { label:'440,000', value: 440000}
]

export const REWARD_PERCENTAGE_OPTIONS: any[] = ((): any[] => {
    const options: any = []
    for (let i = 0; i <= 100; i++) {
        options.push({ label: `${i}%`, value: `${i}` })
    }
    return options
})()

export const PEOPLE_PERCENTAGE_OPTION: any[] = ((): any[] => {
    const options: any = []
    for (let i = 0; i <= 100; i++) {
        options.push({ label: `${i}%`, value: `${i}` })
    }
    return options
})()
